import { EventEmitter, Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Notifications } from '../interfaces/interfaces';
import { HttpService } from './http.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private _subjectActions = [
    {
      subject: 'Nueva promocion',
      url: 'product-detail',
      type: 'Product/notify',
    },
    {
      subject: 'Nuevo producto',
      url: 'product-detail',
      type: 'Product/notify',
    },
    {
      subject: 'Nuevo pedido',
      url: 'admin/paylist',
      type: 'Order/Admin/order',
    },
    {
      subject: 'Producto Agotado',
      url: 'admin/products',
      type: 'Product/stock_out',
    },
    {
      subject: 'Tienes pedidos por verificar',
      url: 'admin/paylist',
      type: 'Order/Admin/alert',
    },
    {
      subject: 'Transaccion realizada',
      url: 'wallet',
      type: 'Transaction/debit',
    },
    {
      subject: 'Transaccion realizada',
      url: 'wallet',
      type: 'Transaction/gift_card',
    },
    {
      subject: 'Transaccion realizada',
      url: 'wallet',
      type: 'Transaction/recharge',
    },
    {
      subject: 'Transaccion realizada',
      url: 'wallet',
      type: 'Transaction/transfer',
    },
    {
      subject: 'Nuevo registro de Mayorista',
      url: 'admin/users',
      type: 'auth/new_register',
    },
    {
      subject: 'Nuevo comentario',
      urlGallery: 'gallery',
      urlProduct: 'product-detail',
      type: 'Comment/new',
    },
  ];
  private _notifications: Notifications[] = [];
  public count$: BehaviorSubject<number>;
  public showNotification = new EventEmitter();
  private count = 0;
  public type = '';
  public page = 1;
  public totalPages = 1;

  constructor(private httpSv: HttpService, private navCtrl: NavController) {
    this.count$ = new BehaviorSubject<number>(this.count);
  }

  async totalNotifications() {
    try {
      const res = await this.httpSv.itemIndex('notification/count');
      this.count = res?.data || 0;
      this.count$.next(this.count);
    } catch (err) {
      console.error(err);
    }
  }

  async getNotifications() {
    try {
      if (this.page <= this.totalPages) {
        const param = {
          pag: this.page,
          notView: this.type,
        };
        const res = await this.httpSv.itemIndex('notification', '', param);
        this.totalPages = res.data.meta.last_page;
        const notificationsData: Notifications[] = res.data.data;
        notificationsData.map((item) => {
          item.data = JSON.parse(item.data);
        });
        this.notifications.push(...notificationsData);
        this.page++;
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async changeType(type) {
    this.notifications = [];
    this.page = 1;
    this.type = type;
  }

  async markAllAsRead() {
    await this.httpSv.itemAction({}, 'notification', 'markAllAsRead');
    await this.totalNotifications();
    this.notifications = [];
    this.page = 1;
    await this.getNotifications();
  }

  get notifications() {
    return this._notifications;
  }

  set notifications(value) {
    this._notifications = value;
  }

  async goToNotification(notification: Notifications) {
    try {
      const subject = this._subjectActions.find(
        (item) => item.type === notification.data.type
      );
      this.marAsSeen(notification.data.notificationId || notification.id);
      if (
        subject.type === 'Order/Admin/order' ||
        subject.type === 'Product/stock_out'
      ) {
        let queryParams: any = {};
        if (notification.data?.order?.id) {
          queryParams.order = notification.data.order.id;
        } else if (notification.data?.product?.id) {
          queryParams.product = notification.data.product.id;
        }
        await this.navCtrl.navigateForward(subject.url, { queryParams });
      } else if (subject.type === 'Comment/new') {
        const notifyData = notification.data;
        const url = notifyData.galleryId
          ? subject.urlGallery
          : subject.urlProduct;
        const id = notifyData.galleryId
          ? notifyData.galleryId
          : notifyData.productId;
        await this.navCtrl.navigateForward(`${url}/${id}`);
      } else if (subject.type === 'Product/notify') {
        await this.navCtrl.navigateForward(
          `${subject.url}/${notification.data.id}`
        );
      } else {
        await this.navCtrl.navigateForward(`${subject.url}`);
      }
      await this.totalNotifications();
    } catch (err) {
      console.error(err);
    }
  }

  async marAsSeen(id) {
    await this.httpSv.getProduct(id, 'notification'); //marcar view
    await this.totalNotifications();
    return true;
  }
}
