import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CarrouselGuard } from './guards/carrousel.guard';
import { LoginGuard } from './guards/login.guard';
import { GuardAdminGuard } from './pages/admin/guard/guard-admin.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'catalogue/:id',
    loadChildren: () => import('./pages/recetas/recetas.module').then( m => m.RecetasPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/vista-compra/vista-compra.module').then( m => m.VistaCompraPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/pages-admin/home/admin.module').then( m => m.AdminPageModule),
    canActivate: [ GuardAdminGuard ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthPageModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [ LoginGuard ]
  },
  {
    path: 'banner',
    loadChildren: () => import('./pages/admin/pages-admin/banner/banner.module').then( m => m.BannerPageModule)
  },
  {
    path: 'product-detail/:id',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then( m => m.ProductDetailPageModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/admin/pages-admin/contacts/contacts.module').then( m => m.ContactsPageModule)
  },
  {
    path: 'sponsors/:id',
    loadChildren: () => import('./pages/sponsors/sponsors.module').then( m => m.SponsorsPageModule)
  },
  {
    path: 'marketplace',
    loadChildren: () => import('./pages/marketplace/marketplace.module').then( m => m.MarketplacePageModule)
  },
  {
    path: 'gallery/:id',
    loadChildren: () => import('./pages/gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  {
    path: 'magazine',
    loadChildren: () => import('./pages/magazine/magazine.module').then( m => m.MagazinePageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'carrousel',
    loadChildren: () => import('./pages/carrousel/carrousel.module').then( m => m.CarrouselPageModule),
    canActivate: [CarrouselGuard]
  },
  {
    path: 'load',
    loadChildren: () => import('./pages/admin/pages-admin/load/load.module').then( m => m.LoadPageModule)
  },
  {
    path: 'contacts-branch',
    loadChildren: () => import('./pages/admin/pages-admin/contacts-branch/contacts-branch.module').then( m => m.ContactsBranchPageModule)
  },
  {
    path: 'favorites',
    loadChildren: () => import('./pages/favorites/favorites.module').then( m => m.FavoritesPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'meeting/:id',
    loadChildren: () => import('./pages/calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'operator',
    loadChildren: () => import('./pages/operator/operator.module').then( m => m.OperatorPageModule)
  },
  {
    path: 'inscriptions',
    loadChildren: () => import('./pages/inscriptions/inscriptions.module').then( m => m.InscriptionsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'pending-orders',
    loadChildren: () => import('./pages/pending-orders/pending-orders.module').then( m => m.PendingOrdersPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'special-request',
    loadChildren: () => import('./pages/special-request/special-request.module').then( m => m.SpecialRequestPageModule)
  },
  {
    path: 'all-catalogue',
    loadChildren: () => import('./pages/all-catalogue/all-catalogue.module').then( m => m.AllCataloguePageModule)
  },
  {
    path: 'payment-proof',
    loadChildren: () => import('./pages/payment-proof/payment-proof.module').then( m => m.PaymentProofPageModule)
  },
  {
    path: '**',
    redirectTo: 'carrousel',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, 
      { 
        preloadingStrategy: PreloadAllModules, 
        initialNavigation: 'enabledBlocking',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
